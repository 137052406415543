<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-black">
            <template slot="header">
              <div class="image-container">
                <img src="img/tolsporA5.png" alt=""/>
              </div>
              <h1 class="card-title"></h1>
            </template>

            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors, validated }"
              >
              <base-input
                required
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-single-02"
                :error="(validated && email && errors[0]) || ''"
                :class="[{ 'has-success': passed }, { 'has-danger': validated && failed && email }]">
              </base-input>
             </ValidationProvider>

             <ValidationProvider
               name="password"
               rules="required|min:5"
               v-slot="{ passed, failed, errors }"
             >
             <base-input
               required
               v-model="password"
               placeholder="Password"
               addon-left-icon="tim-icons icon-lock-circle"
               type="password"
               :error="(password && errors[0]) || ''"
               :class="[{ 'has-success': passed }, { 'has-danger': failed && password }]">
             </base-input>
            </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="tolsporagulur" class="mb-3" size="lg" block>
                Login
              </base-button>
              <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/forgot-password">
                    Forgot Password?
                  </router-link>
                </h6>
              </div>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import config from "../../config"
import authService from "../../services/authService"
import axios from 'axios';
import Cookies from 'js-cookie';
import api from "../../services/api";
import { jwtDecode } from "jwt-decode";
import i18n from '../../i18n';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      email: "",
      password: "",
      subscribe: true
    };
  },
  methods: {
    async submit() {
      try {
        const response = await axios.post(config.apiUrl + '/identity/loginUser', {
          username: this.email,
          password: this.password
        });

        if (response.status === 200) {
          Cookies.set('accessToken', response.data.accessToken, { secure: true, httpOnly: false });
          Cookies.set('refreshToken', response.data.refreshToken, { secure: true, httpOnly: false });
          authService.login();
          this.setLocale(response.data.accessToken);
          const decodedToken = jwtDecode(response.data.accessToken);
          const userRole = decodedToken.role;
          // Navigate to the /mytools route
          if (userRole === "WarehouseUser")
          {
            this.$router.push('/warehouseunreserve');
          }
          else
          {
            this.$router.push('/mytools');
          }
        } else {
          this.$notify({
            message: this.$t('common.loginfail'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
      } catch (error) {
        let message = this.$t('common.loginfail')
        if(error.response.data && error.response.data[0].errorMessage && error.response.data[0].errorMessage != ''){
          message = error.response.data[0].errorMessage
        }

        authService.logout();
        console.error(error);
        alert(message)
      }
    },
    async setLocale(accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const userUrl = `/User/${decodedToken.UserIdentifier}`;
      api.get(userUrl)
        .then(response => {
          // Map the language from the API response to the locale in VueI18n
          Cookies.set('locale',response.data.language,{ secure: true, httpOnly: false })
          switch (response.data.language) {
            case 'en-US':
              i18n.locale = 'en-US';
              break;
            case 'pl-PL':
              i18n.locale = 'pl-PL';
              break;
            case 'is-IS':
              i18n.locale = 'is-IS';
              break;
            case 'lt-LT':
              i18n.locale = 'lt-LT';
              break;
            default:
              i18n.locale = 'en-US';
          }
        })
        .catch(error => {
          console.error('Failed to fetch user locale on startup:', error);
        });
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.image-container {
  height: 250px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}
</style>
