<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h1 class="title">Veldu það sem hentar þér best</h1>
        <h4 class="description">
          Fríar uppfærslur fylgja hverri áskrift.
        </h4>
        <h4 class="description">
          Fyrirtæki með +50 manns vinsamlegast hafið samband við tolspor@tolspor.is og við setjum upp tilboð.
        </h4>
        <h4 class="description">
          Öll verð eru mánaðarlegur kostnaður fyrir hvern notanda.
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-primary"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Stóri</h1>
          <img class="card-img" src="img/card-primary.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">25-50 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <li class="list-group-item">24/7 Support</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>1.490 +vsk</h3>
            <h5 class="text-on-back">1.490</h5>
            <p class="plan">Stór fyrirtæki</p>
          </div>
        </card>
      </div>

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-success card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Miðjan</h1>
          <img class="card-img" src="img/card-success.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">11-24 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <li class="list-group-item">24/7 Support</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>1.790 +vsk</h3>
            <h5 class="text-on-back">1.790</h5>
            <p class="plan">Meðalstór fyrirtæki</p>
          </div>
        </card>
      </div>

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-warning card-raised card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Litli</h1>
          <img class="card-img" src="img/card-warning.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">2-10 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>2.490 +vsk</h3>
            <h5 class="text-on-back">2.490</h5>
            <p class="plan">Lítil fyrirtæki</p>
          </div>
        </card>
      </div>
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-danger card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Einn</h1>
          <img class="card-img" src="img/card-danger.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">1 notandi</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <!-- <li class="list-group-item">No Support</li> -->
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>4.990 +vsk</h3>
            <h5 class="text-on-back">4.990</h5>
            <p class="plan">Einyrkjar</p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'pricing-page',
};
</script>
<style></style>
